<template>
    <div class="container">
        <div class="mapBox" id="mapBox">
            <template v-if="!showMap">
               <div class="loading-box">
                    <van-loading />
                    <p class="text">{{ loadingText }}</p>
                </div>
            </template>
        </div>
        
        
        <!-- 定位按钮 -->
        <div class="location-btn" @click="location">
            <img src="https://lsdj-img.lanshididu.com/dev/wap/6126e0b4-b884-4cbf-a4a4-5b6759aef5c9.png" alt="" class="location-icon">
            <p class="text">定位</p>
        </div>
        <!--  展示卡片 -->
        <div class="cardBox" v-if="showCardInfo">
            <div class="card">
                <p class="close" @click="closeCard">
                    关闭
                </p>
                <div class="content-li" @click.stop="clickAttendant(showCardParams)">
                    <img class="userImg" :src="showCardParams.avatar" alt="">
                    <div class="userInfo">
                        <p>
                            <!-- <span class="userState">可服务</span> -->
                            <span class="name">{{showCardParams.nickName}}</span>
                        </p>
                        <p class="rate-p"><van-rate v-model="showCardParams.star" size="15" readonly  color="orange" /> {{ showCardParams.star }}</p>
                        <p class="plane"><svg-icon icon-class="plane-2" class="planeIcon"></svg-icon> {{showCardParams.distance}}km</p>
                    </div>
                    <div class="time-show-div">
                        <p class="time-show">
                        <span>最早可约</span>
                        <span>{{ getToDay(showCardParams.earliestAppointmentDate) }} {{showCardParams.earliestAppointmentTime}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AMapLoader from '@amap/amap-jsapi-loader'
    import Location from '@/utils/location'
    import ScaleJson from '@/assets/json/scale.json'
    import {getToDay} from "@/utils/index"

    export default {
        props:{
            imgList:{
                type:Array,
                default:()=>[]
            },
            isClick:{
                type:Boolean,
                default:true
            }
        },
        data(){
            return {
                AMap:null,
                map: null,
                scale:null,
                myMarker:null,
                lnglat:new AMap.LngLat(116.397455, 39.919087),
                zoom_to_scale:[],
                showCardParams:null,
                showCardInfo:false,
                showLng:"",
                showLat:"",
                clickFun:true,
                showMap:false,
                loadingText:"绘制地图中..."
            }
        },
        watch:{
            // 监听imgList
            imgList:{
                handler(newVal,oldVal){
                    this.initMap()
                },
                deep:true
            },
            isClick:{
                handler(newVal,oldVal){
                    this.clickFun = newVal
                },
                deep:true
            }
        },
        created(){
            // 如果是单个看地图的功能（防止与imgList监听同时触发多次）
            if(!this.isClick){
                this.initMap()
            }
            this.zoom_to_scale = ScaleJson.zoom_to_scale 
        },
         unmounted() {
            this.map?.destroy();
            //地图对象赋值为null
            this.map = null
            //清除地图容器的 DOM 元素
            document.getElementById("mapBox").remove(); //"container" 为指定 DOM 元素的id
        },
        methods:{
            closeCard(){
                this.showCardParams = null;
                this.showCardInfo = false
            },
            getToDay(str){
                return getToDay(str)
            },
            clickAttendant(item){
                this.$router.push({path:'/attendantDetails', query:{id:item.id}})
            },
             // 定位当前
            location(){
                Location.getCurrentLocation(this.AMap, (result) => {
                    //定位成功，获取经纬度
                    this.lnglat = result.position;
                    // 设置marker位置
                    this.myMarker.setPosition(this.lnglat);
                    // 设置中心点位置
                    this.map.setCenter(this.lnglat);
                }, () => {
                    this.$toast("定位失败，请检查网络或GPS是否正常开启")
                })
            },
            initMap(){
                AMapLoader.load({
                    plugins: ["AMap.Scale", "AMap.Geolocation"],
                }).then((AMap) => {
                    this.AMap = AMap

                    const selectCityAddress = JSON.parse(sessionStorage.getItem('selectCityAddress'))
                    //  判断是否有选择的定位信息
                    if (selectCityAddress) {
                        // sessionStorage.removeItem('selectCityAddress')
                        this.lnglat = new AMap.LngLat(selectCityAddress.cityInfo.lng, selectCityAddress.cityInfo.lat)
                        this.loadingText = "绘制地图中..."
                        this.drawMap()
                    }else{
                        // 定位
                         this.loadingText = "定位中..."
                        Location.getCurrentLocation(AMap, (result) => {
                            console.log("-----高德定位----")
                            //定位成功，获取经纬度
                            this.lnglat = result.position;
                            console.log(this.lnglat)
                            this.loadingText = "绘制地图中..."
                            this.drawMap()
                        }, () => {
                            //定位失败，使用默认的经纬度
                            this.lnglat = new AMap.LngLat(116.397455, 39.919087)
                            this.$toast("定位失败，请检查网络或GPS是否正常开启")
                            this.loadingText = "采用默认定位绘制地图中..."
                            this.drawMap()
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                });
            },
            drawMap(){
                console.log("-----准备绘制地图-----")
                    // 绘制地图
                    this.map = new this.AMap.Map("mapBox", {
                        // 设置地图容器id
                        viewMode: "3D", // 是否为3D地图模式
                        zoom: 14, // 初始化地图级别
                        center:this.lnglat, // 初始化地图中心点位置
                    });

                    this.scale = new this.AMap.Scale();  
                    this.map.addControl(this.scale);
                      

                     this.map.on('complete', async () => {
                        //地图图块加载完成后触发
                        console.log('地图加载完毕')
                        this.showMap = true

                        //在运行阶段添加点中心点标记
                        this.myMarker = new this.AMap.Marker({
                            position: this.lnglat,
                            icon:"https://lsdj-img.lanshididu.com/b4b54f7c1b00d005d0fda6c91774e85.png",
                            offset: new this.AMap.Pixel(-13, -40)            // 设置点标记偏移量
                        });

                        // this.map.add(this.myMarker);

                        let markerList = [this.myMarker]
                        // console.log("-------开始绘制Marker--------")
                        // console.log(markerList)
                        // console.log(this.imgList)
                        
                        await this.imgList.forEach(item => {

                            const content = `<div class="custom-content-marker" style="background-image:url(${item.avatar})"></div>`;

                            let marker = new this.AMap.Marker({
                                position: new this.AMap.LngLat(item.longitude, item.latitude),
                                content:content,
                                // offset: new AMap.Pixel(-13, -40)            // 设置点标记偏移量
                            });

                            marker.locationData = {lng:item.longitude, lat:item.latitude}
                            if(this.isClick){
                                 marker.on('click', (e) => {
                                this.showCardInfo = true
                                console.log(e)
                                // let item = e.target.getExtData().item; // 获取item , 这个getExtData()是AMap.Marker的扩展方法
                                this.completeShowCard(e.target.locationData.lng, e.target.locationData.lat)
                            });
                            }
                            
                            
                            //  将点标记添加到已有的地图实例：
                            
                            markerList.push(marker)
                        })
                        // console.log('-------组装完毕----开始添加----')
                        // 将点标记添加到已有的地图实例：
                        this.map.add(markerList);

                        //  如果不可点击，证明是显示单个的，不可点击
                        if(!this.isClick){
                            this.map.setFitView();
                        }
                    });

                    this.map.on("zoomend",  () => {
                        let currentZoom = this.map.getZoom(); //获取地图级别
                        let custom_mark_img = document.getElementsByClassName('custom-content-marker');
                        if(currentZoom <= 14){
                            let zoom = Math.round(currentZoom); 
                            let scaleSize = 1
                            // // 利用transform缩放
                            for(let i = 0; i < this.zoom_to_scale.length; i++){
                                if(zoom  == this.zoom_to_scale[i].zoom){
                                    scaleSize =  this.zoom_to_scale[i].scale
                                }
                            }

                            // // let 
                            for(let i = 0; i < custom_mark_img.length; i++){
                                // 利用transform缩放
                                custom_mark_img[i].style.transform = `scale(${scaleSize})`;
                            }

                        }
                    });
            },
            // 计算匹配点击的经纬度
            completeShowCard(lng, lat){
               
               this.imgList.forEach((item) => {
                    if(item.latitude == lat && item.longitude == lng){
                        this.showCardParams = item
                    }
               })

               this.showCardInfo = true

               console.log(this.showCardInfo)
            }
        }
    }
</script>

<style lang="less" scoped>
    // @import "../styles/serviceList.less";

    .container{
       height: 100%;
        position: relative;
    }
    .mapBox{
      height: 100%;
      position: relative;
      
    }

    .loading-box{
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text{
            color: #ccc;
            margin-top: .2rem;
        }
    }

     .location-btn{
            position: absolute;
            width: 1rem;
            height: 1rem;
            background-color: #fff;
            border-radius: 10%;
            bottom: .5rem;
            right: .5rem;
            z-index: 999;
            box-shadow: 0 0 10px #ccc;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .location-icon{
                width: .35rem;
                height: .35rem;
                color: red;
            }
            .text{
                margin-top: .15rem;
                font-size: .25rem;
            }
        }

        .cardBox{
            padding: .3rem .4rem;
            position: absolute;
            bottom: 0rem;
            left: 0;
            right: 0;
            z-index: 998;
            .card{
                // width: 100%;
                // height: 100%;
                background-color: #fff;
                border-radius: .2rem;
                .close{
                    text-align: right;
                    padding-right: .3rem;
                    padding-top: .2rem;
                    font-size: .35rem;
                    color: rgba(0, 0, 0, 0.3);
                }
            }
        }


    .content-li {
    display: flex;
    background: white;
    border-radius: .2rem;
    padding: .3rem;
    position: relative;

    &:not(:last-child) {
        margin-bottom: 0.3rem;
    }

    .userImg {
        width: 2rem;
        height: 2rem;
        margin-right: 0.4rem;
    }

    .userInfo {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
            font-weight: bold;
        }

        .plane {
            color: #1296db;
        }
    }

    .time-show-div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    .time-show {
        display: flex;
        flex-direction: column;
        font-size: .28rem;
        color: #EB4E52;
        border: solid 1px #F9CACA;
        border-radius: 3px;

        span {
            padding: 2px 5px;

            &:first-child {
                background: #FBDDDF;
            }
        }
    }

    .drop {
        display: flex;
        flex-direction: column;
        width: .5rem;
        align-items: center;

        span {
            width: 3px;
            height: 3px;
            background: #000;
            border-radius: 50%;

            &:not(:last-child) {
                margin-bottom: 3px;
            }
        }
    }

    .popover-div {
        position: absolute;
        right: 0.4rem;
        bottom: 0.2rem;
        background: rgb(228, 223, 223);
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.18rem .2rem;
        border-radius: .1rem;
        box-shadow: 1px 1px 1px 1px rgb(228, 223, 223);

        .unlikeIcon {
            font-size: .4rem;
            margin-right: 0.1rem;
        }

    }
}

    
</style>